import React from 'react';
import FreakyLogin from './components/FreakyLogin';

function App() {
  return (
    <div className="App">
      <FreakyLogin />
    </div>
  );
}

export default App;
