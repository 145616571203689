import React, { useState, useRef } from 'react';
import { Search } from 'lucide-react';

const FreakyLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const audioRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Login attempted:', { email, password });
    // Play the evil sound
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset audio to start
      audioRef.current.play();
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      const searchTerm = encodeURIComponent(`${searchQuery} freaky`);
      window.open(`https://www.google.com/search?q=${searchTerm}`, '_blank');
    }
  };

  const handleGetFreaky = () => {
    window.open('https://youtu.be/X_lkoL9Zpws?t=23', '_blank');
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
      {/* Hidden audio element */}
      <audio ref={audioRef}>
        <source src="https://cdn.ayokaacr.net/youtube_2W-ZxMeL-Bw_audio.mp3" type="audio/mpeg" />
      </audio>

      <div className="w-full max-w-md">
        <div className="bg-gray-800 p-4 rounded-t-lg flex items-center gap-2">
          <form onSubmit={handleSearch} className="flex items-center gap-2 flex-1">
            <Search 
              className="text-gray-400 cursor-pointer" 
              size={20} 
              onClick={handleSearch}
            />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="freaky ass search"
              className="bg-gray-700 rounded px-3 py-1 text-gray-200 flex-1 focus:outline-none focus:ring-1 focus:ring-purple-500"
            />
          </form>
          <div className="space-y-1 cursor-pointer">
            <div className="w-6 h-0.5 bg-gray-400"></div>
            <div className="w-6 h-0.5 bg-gray-400"></div>
            <div className="w-6 h-0.5 bg-gray-400"></div>
          </div>
        </div>

        <div className="bg-gray-800 p-8 rounded-b-lg space-y-6">
          <h2 className="text-3xl text-gray-200 font-medium mb-8 text-center" 
              style={{ fontFamily: 'cursive' }}>
            freaky login
          </h2>
          
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label className="block text-gray-400" style={{ fontFamily: 'cursive' }}>
                freaky e-mail
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-purple-500"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-gray-400" style={{ fontFamily: 'cursive' }}>
                freaky password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 focus:outline-none focus:border-purple-500"
              />
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                className="flex-1 bg-purple-800 hover:bg-purple-700 text-gray-200 py-2 px-4 rounded transition-colors"
                style={{ fontFamily: 'cursive' }}
              >
                evil login
              </button>
              
              <button
                type="button"
                onClick={handleGetFreaky}
                className="flex-1 bg-purple-800 hover:bg-purple-700 text-gray-200 py-2 px-4 rounded transition-colors"
                style={{ fontFamily: 'cursive' }}
              >
                get freaky
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FreakyLogin;